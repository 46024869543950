import React from "react";
import Div100vh from "react-div-100vh";

import { ReactComponent as FinskeLogo } from "./finske.svg";
import { ReactComponent as Instagram } from "./instagram.svg";

export default function App() {
  return (
    <Div100vh>
      <div className="finske-container">
        <div className="logo-wrapper">
          <FinskeLogo />
        </div>
        <div className="link-wrapper">
          <a href="https://www.instagram.com/finske.sk/" target="_blank">
            <Instagram />
          </a>
        </div>
      </div>
    </Div100vh>
  );
}
